/*
-------------------------------------------------
GENERAL/BASIC
Basis Einstellungen
-------------------------------------------------
*/

svg {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.image_container {
  img {
    border: 1px solid $border-color-invers;
  }
}
