/*
=========================================================
COMPONENTS CATALOG FORM MIDWIFE
individuelles Formular Frontendediting hebammenlisten.de
=========================================================
*/

.ctlg_form_midwife {
  .widget {
    display: flex;
    flex-flow: wrap;
    label {
      width: 100%;
      margin-right: 0.4rem;
      @include medium {
        width: 13rem;
      }
      @include large {
        width: 100%;
      }
    }
    input,
    .chzn-container {
      flex: 1 0 21rem;
      max-width: 100%;
      margin: 0;
    }
    .mce-tinymce {
      width: 100%;
      margin: 0;
    }
  }
  .v-component {
    display: flex;
    flex-flow: wrap;
    label {
      width: 100%;
      margin-right: 0.4rem;
      @include medium {
        width: 13rem;
      }
      @include large {
        width: 100%;
      }
    }
    .multi-dates-wizard-component {
      flex: 1 0 21rem;
      max-width: 100%;
      margin: 0;
      .multi-dates-wizard {
        .table {
          width: 100%;
          max-width: 100%;
          .tbody {
            .tr {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              .td {
                &.date {
                  margin-right: 0.4rem;
                  input {
                    max-width: 100%;
                  }
                }
                &.operations {
                  flex: 1 0 9rem;
                  width: auto;
                  display: flex;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  button {
                    margin: 0 0.4rem 0 0;
                    &.drag {
                      display: none;
                    }
                    &.delete {
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
          .thead {
            .tr {
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-between;
              .th {
                &.from,
                &.to {
                  margin-right: 0.4rem;
                }
                &.operation {
                  flex: 1 0 9rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .widget-fine-uploader {
    display: block;
    label {
      width: 100%;
    }
    .image_container {
      float: none;
      max-width: 150px;
      margin-right: 0;
    }
  }
  .qq-uploader {
    .qq-upload-button {
      background: $bg-color-accent;
      border: 1px solid $border-color-accent;
    }
  }
}
