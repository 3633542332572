/*
================================================================================
Stylesheet Hebammenlisten (webfont.css)
Theme Rocksolid Luxe
lokale Webfonts in eigener Datei laden
benutzt wird die library Bourbon
================================================================================
*/
/*
=========================================================
GENERAL FONTS
Google Fonts lokal einbinden
=========================================================
*/
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open_sans/open-sans-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open_sans/open-sans-v15-latin-regular.woff2") format("woff2"), url("../fonts/open_sans/open-sans-v15-latin-regular.woff") format("woff"), url("../fonts/open_sans/open-sans-v15-latin-regular.ttf") format("truetype"), url("../fonts/open_sans/open-sans-v15-latin-regular.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open_sans/open-sans-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open_sans/open-sans-v15-latin-700.woff2") format("woff2"), url("../fonts/open_sans/open-sans-v15-latin-700.woff") format("woff"), url("../fonts/open_sans/open-sans-v15-latin-700.ttf") format("truetype"), url("../fonts/open_sans/open-sans-v15-latin-700.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open_sans/open-sans-v15-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open_sans/open-sans-v15-latin-italic.woff2") format("woff2"), url("../fonts/open_sans/open-sans-v15-latin-italic.woff") format("woff"), url("../fonts/open_sans/open-sans-v15-latin-italic.ttf") format("truetype"), url("../fonts/open_sans/open-sans-v15-latin-italic.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/open_sans/open-sans-v15-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open_sans/open-sans-v15-latin-700italic.woff2") format("woff2"), url("../fonts/open_sans/open-sans-v15-latin-700italic.woff") format("woff"), url("../fonts/open_sans/open-sans-v15-latin-700italic.ttf") format("truetype"), url("../fonts/open_sans/open-sans-v15-latin-700italic.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../fonts/noto_serif/noto-serif-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/noto_serif/noto-serif-v8-latin-regular.woff2") format("woff2"), url("../fonts/noto_serif/noto-serif-v8-latin-regular.woff") format("woff"), url("../fonts/noto_serif/noto-serif-v8-latin-regular.ttf") format("truetype"), url("../fonts/noto_serif/noto-serif-v8-latin-regular.svg#Noto Serif") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../fonts/noto_serif/noto-serif-v8-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/noto_serif/noto-serif-v8-latin-700.woff2") format("woff2"), url("../fonts/noto_serif/noto-serif-v8-latin-700.woff") format("woff"), url("../fonts/noto_serif/noto-serif-v8-latin-700.ttf") format("truetype"), url("../fonts/noto_serif/noto-serif-v8-latin-700.svg#Noto Serif") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../fonts/noto_serif/noto-serif-v8-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/noto_serif/noto-serif-v8-latin-italic.woff2") format("woff2"), url("../fonts/noto_serif/noto-serif-v8-latin-italic.woff") format("woff"), url("../fonts/noto_serif/noto-serif-v8-latin-italic.ttf") format("truetype"), url("../fonts/noto_serif/noto-serif-v8-latin-italic.svg#Noto Serif") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../fonts/noto_serif/noto-serif-v8-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/noto_serif/noto-serif-v8-latin-700italic.woff2") format("woff2"), url("../fonts/noto_serif/noto-serif-v8-latin-700italic.woff") format("woff"), url("../fonts/noto_serif/noto-serif-v8-latin-700italic.ttf") format("truetype"), url("../fonts/noto_serif/noto-serif-v8-latin-700italic.svg#Noto Serif") format("svg");
  font-weight: 700;
  font-style: italic;
}
