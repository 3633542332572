/*
=========================================================
COMPONENTS CATALOG TEASER MIDWIVES
individuelle Anpassungen hebammenlisten.de
=========================================================
*/

.mod_catalogUniversalView {
  &.ctlg_midwives {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    row-gap: 1vh;
    @include medium {
      // grid-template-columns: 520px;
    }
    @include min(1000px) {
      grid-template-columns: calc(50% - 1vw) calc(50% - 1vw);
      column-gap: 1vw;
    }
  }
  .ctlg_view_message {
    @include min(1000px) {
      grid-column: 1 / span 2;
    }
  }
  .pagination {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: -1;
  }
}
.teaser_midwife {
  font-size: $font-size-small / 15 * 1rem;
  border: 1px solid $border-color;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "contact"
    "more"
    "available";
  @include small {
    grid-template-columns: 38% 62%;
    grid-template-rows: 1vw auto 1.6em 1vw;
    grid-template-areas:
      "avatar ."
      "avatar contact"
      "available more"
      "available .";
    height: 205px;
  }
  @include min(550px) {
    grid-template-columns: 32% 68%;
  }
  @include medium {
    grid-template-columns: 32% 68%;
    max-width: 520px;
  }
  @include min(630px) {
    grid-template-columns: 31% 69%;
  }
  @include large {
    grid-template-columns: 36% 64%;
    height: 222px;
  }

  .avatar {
    display: none;
    @include small {
      display: block;
      background-color: $bg-color-alternative-special;
      grid-area: avatar;
    }
  }
  h3 {
    color: $color-alternative-special;
    font-size: 1.2em;
    margin: 0 0 1rem;
    a {
      color: $color-alternative-special;
    }
  }
  .contact {
    grid-area: contact;
    align-self: center;
    padding: 0.8rem;
    @include small {
      padding: 0 0.8rem;
    }
  }
  .more {
    grid-area: more;
    padding-left: 1rem;
  }

  p {
    font-size: 1;
    margin: 0;
  }
  .available {
    font-style: oblique;
    grid-area: available;
    align-self: end;
    color: $color-invers;
    background-color: $bg-color-accent;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    &.free {
      background-color: $bg-color-accent-alternative;
    }
  }
}
