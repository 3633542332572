/*
=========================================================
COMPONENTS TINYMCE_FRONTEND
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mce-tinymce {
  .mce-btn {
    button {
      width: 100%;
      margin: 0;
    }
  }
}
