/*
=========================================================
GENERAL FONTS
Google Fonts lokal einbinden
=========================================================
*/

// Open Sans
@include font-face(
  "Open Sans",
  "../fonts/open_sans/open-sans-v15-latin-regular",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 400;
  font-style: normal;
}
@include font-face("Open Sans", "../fonts/open_sans/open-sans-v15-latin-700", ("woff", "woff2", "eot", "ttf", "svg")) {
  font-weight: 700;
  font-style: normal;
}
@include font-face(
  "Open Sans",
  "../fonts/open_sans/open-sans-v15-latin-italic",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 400;
  font-style: italic;
}
@include font-face(
  "Open Sans",
  "../fonts/open_sans/open-sans-v15-latin-700italic",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 700;
  font-style: italic;
}

// Noto Serif
@include font-face(
  "Noto Serif",
  "../fonts/noto_serif/noto-serif-v8-latin-regular",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 400;
  font-style: normal;
}
@include font-face(
  "Noto Serif",
  "../fonts/noto_serif/noto-serif-v8-latin-700",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 700;
  font-style: normal;
}
@include font-face(
  "Noto Serif",
  "../fonts/noto_serif/noto-serif-v8-latin-italic",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 400;
  font-style: italic;
}
@include font-face(
  "Noto Serif",
  "../fonts/noto_serif/noto-serif-v8-latin-700italic",
  ("woff", "woff2", "eot", "ttf", "svg")
) {
  font-weight: 700;
  font-style: italic;
}
