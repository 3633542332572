/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_login {
  .checkbox_container {
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
    border: none;
  }
  .submit {
    margin-left: 0;
    margin-top: 0;
  }
  form {
    p {
      &.error {
        width: 100%;
      }
    }
  }
}
