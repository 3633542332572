/*
=========================================================
COMPONENTS CATALOG FILTER SEARCH MIDWIVES
individuelle Anpassungen hebammenlisten.de
=========================================================
*/
.main-content {
  .filter_midwives_search {
	@include medium {
	  display:               grid;
	  grid-template-columns: 1fr 1fr;
	  grid-template-rows: auto;
	  grid-template-areas:
      "region town"
      "service language"
      "termin search"
      "submit .";
	  column-gap:            1vw;
	  row-gap:               2vh;
	}
	@include min(1000px) {
	  grid-template-columns: 1fr 1fr 1fr;
	  grid-template-areas: "town search submit";
	}
	.ctlg_form_field {
	  display:   flex;
	  flex-flow: column;
	  select,
	  input,
	  button {
		width:  auto;
		margin: 0;
	  }
	  label {
		font-weight: 700;
		width:       100%;
	  }
	}
	.region {
	  grid-area: region;
	  @include min(1000px) {
		display: none;
	  }
	}
	.town {
	  grid-area: town;
	}
	.service {
	  grid-area: service;
	  @include min(1000px) {
		display: none;
	  }
	  option[selected] {
		background-color: $bg-color-accent;
		color:            $color-invers;
	  }
	}
	.language {
	  grid-area: language;
	  @include min(1000px) {
		display: none;
	  }
	}
	.termin {
	  grid-area: termin;
	  @include min(1000px) {
		display: none;
	  }
	}
	.search {
	  grid-area: search;
	}
	.submit_container {
	  align-self: end;
	  margin-top: 1.2em;
	  grid-area:  submit;
	  @include medium {
		margin-top: 0;
	  }
	}
	button {
	  display: block;
	  width:   100%;
	  margin:  0;
	}
  }
  .reset {
	font-weight: 700;
  }
  .pdf_print {
	@include medium {
	  display:               grid;
	  grid-template-columns: minmax(298.717px, 1fr) 1fr;
	  grid-template-rows: auto;
	  grid-template-areas:
      "submit .";
	  column-gap:            1vw;
	  row-gap:               2vh;
	}
	@include min(1000px) {
	  grid-template-columns: 1fr 1fr 1fr;
	  grid-template-areas: ". . submit";
	}
	button {
	  display:   block;
	  width:     100%;
	  margin:    0;
	  grid-area: submit;
	}
  }
}
