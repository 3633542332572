// ==================================================
// Custom settings Luxe
// individuelle Anpassungen hebammenlisten.de
// ==================================================
// Layout
$max-width-content: 1100px; // maximale Breite des Hauptcontainers

// Breakpoints
$b-xxsmall: 320px;
$b-xxsmall-max: 399px; // Mobile Hochformat
$b-xsmall: 400px;
// $b-xsmall-max: 499px;
$b-small: 520px;
$b-small-max: 599px; // Mobile
$b-medium: 600px;
$b-medium-max: 900px; //Tablet
$b-large: 901px;
// $b-large-max: 1034px;
$b-xlarge: 1035px;
$b-xlarge-max: 1100px; // Desktop
$b-xxlarge: 1100px;

// Colorset
// $alabaster: #fcfcfc; // alte Button-2-Hintergrundfarbe
$wild-sand: #f5f5f5;
$athens-gray: #e5e8ee;
$alto: #d9d9d9;
$quill-gray: #dad9d7;
$nobel: #b6b6b6;
$silver-chalice: #a5a5a5;
// $gray: #919191; // alte Button-2-Schriftfarbe
$tapa: #72716e;
// $tapa: #71706e; // alte Navigationsfarbe Hell
$chicago: #595856;
// $tundora: #4c4c4c; // alte Überschriftfarbe 2
// $fuscous-gray: #4a4947; // alte Navigationsfarbe
$merlin: #41403e;
// $mine-shaft: #393939; //  alte Hintergrundfarbe Mobile Navigation
$cod-gray: #1a1a1a;
// $cod-gray: #151515; // alte Rahmenfarbe Mobile Navigation
$sandstone: #77685e;
$gamboge: #f19d09;
$conifer: #a2d555;
// $sushi: #86c44a; // alte Farbe Confirmation
$steel-blue: #4380b9;
$chestnut: #b94343;

// Content colors
$color: $merlin; // Schriftfarbe, Überschriftfarbe 2, Button-2-Schriftfarbe, Navigationsfarbe
$color-alternative: $tapa; // Schriftfarbe hell, Navigationsfarbe hell
$color-alternative-special: $silver-chalice; // Ueberschrift Catalog Teaser-Element
$color-invers: white; // Ueberschriftfarbe Fusszeile, Footer-Link-Farbe, Navigationsfarbe Mobile Navigation, Button-Schriftfarbe
$color-invers-alternative: $quill-gray; // Schriftfarbe Fußzeile
$color-accent: $gamboge; // Highlight-Farbe, Überschriftfarbe 1, Link-Farbe, Navigationsfarbe Hover, Navigationsfarbe Aktiv
$color-accent-alternative: $conifer; // Confirmation-Farbe
$color-warning: $chestnut; // Warning-Farbe
$color-information: $steel-blue; //Information-Farbe

// Backgrounds
$bg-color: white; // Body Hintergrundfarbe, Hintergrundfarbe der Seite, Standard Hintergrundfarbe, Hintergrundfarbe Dropdown
$bg-color-alternative: $wild-sand; // Button-2-Hintergrundfarbe
$bg-color-alternative-special: $alto; // Hintergrund Avatar
$bg-color-accent: $gamboge; //Auswahlfarbe,  Button-Hintergrundfarbe
$bg-color-accent-alternative: $conifer;
$bg-color-invers: $merlin; // Hintergrundfarbe Mobile Navigation
$bg-color-invers-alternative: $chicago; // Hintergrundfarbe Fusszeile

// Border
$border-color: $silver-chalice; // Rahmenfarbe dunkel
$border-color-alternative: $nobel; // Rahmenfarbe mittel
$border-color-invers: $alto; // Rahmenfarbe hell
$border-color-invers-alternative: $athens-gray;
$border-color-accent: $gamboge;
$border-color-accent-alternative: $cod-gray; // Rahmenfarbe mobile Navigation
$border-radius: 3px;

// Shadows
$shadow-color: $cod-gray; // Schatten Standardfarbe
$shadow-color-alternative: $silver-chalice;

// Distance
$distance: 8 / 16 * 1rem;

// Typographie
$font-family: "Open Sans", sans-serif; //Schriftart
$font-family-alternativ: "Noto Serif", Georgia, serif; // sekundaere Schriftart
$base-font-size: 15px; // Basis-Schriftgroesse
$base-font-size-alternative: 13px; // Schriftgroesse fuer mobile Version
$line-height: 1.6; // Zeilenhoehe
// $font-size-xsmall: 12;
$font-size-small: 13;
$font-size-normal: 15;
// $font-size-large: 20;
// $font-size-xlarge: 28;
// $font-size-h1: 36;
// $font-size-h2: 26;
// $font-size-h3: 24;
// $font-size-h4: 20;
// $font-size-h5: 18;
// $font-size-h6: 16;
