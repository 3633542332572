/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_registration {
  .checkbox_container {
    border: none;
    legend {
      display: none;
    }
  }
}
