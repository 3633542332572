/*
=========================================================
COMPONENTS CATALOG FILTER OVERVIEW MIDWIVES
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_overview {
  // display: none;
  // @include min(1000px) {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  //   grid-template-rows: auto;
  //   grid-column-gap: 1vw;
  //   grid-row-gap: 1vh;
  // }
  h2 {
    font-family: $font-family;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1.2vh;
  }
  ul {
    list-style: none;
    padding: 0;
    a {
      color: $color;
      &:hover {
        color: $color-accent;
      }
    }
    li {
      &.active {
        a {
          color: $color-accent;
        }
      }
    }
  }
}
.mod_overview {
  form {
    display: none;
    @include min(1000px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 1vw;
      grid-row-gap: 1vh;
    }
  }
  input {
    display: none;
  }
  .checkbox,
  .radio {
    &.active {
      color: $color-accent;
    }
    label {
      width: auto;
    }
  }
}

