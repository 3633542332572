/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.ctlg_hebamme {
  grid-column-start: 1;
  grid-column-end: -1;
  .midwife_service {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: wrap;
    li {
      border: 1px solid $border-color-invers;
      text-align: center;
      padding: $distance;
      border-radius: $border-radius;
      margin: 0 $distance $distance 0;
    }
  }
  .ctlg_entities {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $distance;
    column-gap: 1rem;
    @include large {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .headline,
    .ce_text,
    .ce_image {
      grid-column-start: 1;
      grid-column-end: -1;
    }
    .image_container {
      width: auto !important;
      img {
        width: auto;
      }
    }
    .ce_text {
      p {
        margin-top: 0;
      }
    }
    .availability {
      ul {
        list-style: none;
        padding: 0;
      }
    }
  }
  .contact,
  .language,
  .region {
    p {
      margin: 0;
    }
    .city {
      font-weight: 700;
    }
  }
  .back {
    clear: both;
    margin-top: 8 * $distance;
  }
}
