/* normalize.css 2012-03-11T12:53 UTC - http://github.com/necolas/normalize.css */

/* =============================================================================
 * HTML5 display definitions
 * ========================================================================== */

/* Corrects block display not defined in IE6/7/8/9 & FF3 */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
	display: block;
}

/* Corrects inline-block display not defined in IE6/7/8/9 & FF3 */

audio, canvas, video {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}

/* Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices */

audio:not([controls]) {
	display: none;
	height: 0;
}

/* Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support */

[hidden] {
	display: none;
}

/* =============================================================================
 * Base
 * ========================================================================== */

/* 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */

html {
	font-size: 100%;
	/* 1 */
	-webkit-text-size-adjust: 100%;
	/* 2 */
	-ms-text-size-adjust: 100%;
	/* 2 */
	font-family: sans-serif;
}

/* Addresses font-family inconsistency between 'textarea' and other form elements. */

button, input, select, textarea {
	font-family: sans-serif;
}

/* Addresses margins handled incorrectly in IE6/7 */

body {
	margin: 0;
}

/* =============================================================================
 * Links
 * ========================================================================== */

/* Addresses outline displayed oddly in Chrome */

a {
	&:focus {
		outline: thin dotted;
	}
	&:hover, &:active {
		outline: 0;
	}
}

/* Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test */

/* =============================================================================
 * Typography
 * ========================================================================== */

/* Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5 */

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

h2 {
	font-size: 1.5em;
	margin: 0.83em 0;
}

h3 {
	font-size: 1.17em;
	margin: 1em 0;
}

h4 {
	font-size: 1em;
	margin: 1.33em 0;
}

h5 {
	font-size: 0.83em;
	margin: 1.67em 0;
}

h6 {
	font-size: 0.75em;
	margin: 2.33em 0;
}

/* Addresses styling not present in IE7/8/9, S5, Chrome */

abbr[title] {
	border-bottom: 1px dotted;
}

/* Addresses style set to 'bolder' in FF3+, S4/5, Chrome */

b, strong {
	font-weight: bold;
}

blockquote {
	margin: 1em 40px;
}

/* Addresses styling not present in S5, Chrome */

dfn {
	font-style: italic;
}

/* Addresses styling not present in IE6/7/8/9 */

mark {
	background: yellow;
	color: black;
}

/* Addresses margins set differently in IE6/7 */

p {
	margin: 1em 0;
}

pre {
	margin: 1em 0;
	font-family: monospace, serif;
	_font-family: "courier new", monospace;
	font-size: 1em;
}

/* Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59 */

code, kbd, samp {
	font-family: monospace, serif;
	_font-family: "courier new", monospace;
	font-size: 1em;
}

/* Improves readability of pre-formatted text in all browsers */

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

/* 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4 */

/* 1 */

q {
	quotes: none;
	&:before, &:after {
		content: "";
		content: none;
	}
}

/* 2 */

small {
	font-size: 75%;
}

/* Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930 */

sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.25em;
}

sub {
	bottom: -0.2em;
}

/* =============================================================================
 * Lists
 * ========================================================================== */

/* Addresses margins set differently in IE6/7 */

dl, menu, ol, ul {
	margin: 1em 0;
}

dd {
	margin: 0 0 0 40px;
}

/* Addresses paddings set differently in IE6/7 */

menu, ol, ul {
	padding: 0 0 0 40px;
}

/* Corrects list images handled incorrectly in IE7 */

nav {
	ul, ol {
		list-style: none;
		list-style-image: none;
	}
}

/* =============================================================================
 * Embedded content
 * ========================================================================== */

/* 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/ */

img {
	border: 0;
	/* 1 */
	-ms-interpolation-mode: bicubic;
	/* 2 */
}

/* Corrects overflow displayed oddly in IE9 */

svg:not(:root) {
	overflow: hidden;
}

/* =============================================================================
 * Figures
 * ========================================================================== */

/* Addresses margin not present in IE6/7/8/9, S5, O11 */

figure, form {
	margin: 0;
}

/* =============================================================================
 * Forms
 * ========================================================================== */

/* Corrects margin displayed oddly in IE6/7 */

/* Define consistent border, margin, and padding */

fieldset {
	border: 1px solid silver;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

/* 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3
 * 3. Corrects alignment displayed oddly in IE6/7 */

legend {
	border: 0;
	/* 1 */
	padding: 0;
	white-space: normal;
	/* 2 */
	*margin-left: -7px;
	/* 3 */
}

/* 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers */

button, input, select, textarea {
	font-size: 100%;
	/* 1 */
	margin: 0;
	/* 2 */
	vertical-align: baseline;
	/* 3 */
	*vertical-align: middle;
	/* 3 */
}

/* Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet */

button, input {
	line-height: normal;
	/* 1 */
}

/* 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6 */

button {
	cursor: pointer;
	/* 1 */
	-webkit-appearance: button;
	/* 2 */
	*overflow: visible;
	/* 3 */
}

input {
	&[type="button"], &[type="reset"], &[type="submit"] {
		cursor: pointer;
		/* 1 */
		-webkit-appearance: button;
		/* 2 */
		*overflow: visible;
		/* 3 */
	}
}

/* Re-set default cursor for disabled elements */

button[disabled] {
	cursor: default;
}

input {
	&[disabled] {
		cursor: default;
	}
	&[type="checkbox"], &[type="radio"] {
		box-sizing: border-box;
		/* 1 */
		padding: 0;
		/* 2 */
		*height: 13px;
		/* 3 */
		*width: 13px;
		/* 3 */
	}
	&[type="search"] {
		-webkit-appearance: textfield;
		&::-webkit-search-decoration, &::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}
}

/* 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
 *    Known issue: excess padding remains in IE6 */

/* 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof) */

/* Removes inner padding and search cancel button in S5, Chrome on OS X */

/* Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */

button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/* 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers */

textarea {
	overflow: auto;
	/* 1 */
	vertical-align: top;
	/* 2 */
}

/* =============================================================================
 * Tables
 * ========================================================================== */

/* Remove most spacing between table cells */

table {
	border-collapse: collapse;
	border-spacing: 0;
}
