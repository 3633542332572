/*
=========================================================
COMPONENTS HEADER BAR
individuelles Frontendmodul hebammenlisten.de
=========================================================
*/
#header_bar {
  background-color: $bg-color-alternative;
  border-bottom: 1px solid $border-color-invers-alternative;
  .inside {
    max-width: $max-width-content;
    margin: 0 auto;
    padding: $distance 0;
  }
  ul {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    @include xsmall {
      justify-content: flex-end;
    }
  }
  li {
    @include xsmall {
      margin-left: 3 * $distance;
    }
  }
  a {
    font-weight: 700;
  }
  .mod_customnav {
    margin: 0 4%;
  }
}
