/*
=========================================================
COMPONENTS FEATURE BOX
individuelle Anpassungen hebammenlisten.de
=========================================================
*/

.feature-box-image {
  // border: 1px solid rgba($border-color, 0.75);
}
