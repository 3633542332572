/*
================================================================================
Stylesheet Hebammenlisten (special.css)
Autor: Maren Lange
Anpassung Theme Rocksolid Luxe mit Catalog Manager
benutzt wird die library Bourbon
================================================================================
TODO Veraenderungen im Themeassistenten besser alle direkt einarbeiten
      - angepasste Media Queries
      - Boxed-Layout
*/
/*
-------------------------------------------------
GENERAL/BASIC
Basis Einstellungen
-------------------------------------------------
*/
svg {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.image_container img {
  border: 1px solid #d9d9d9;
}

/*
=========================================================
COMPONENTS CATALOG FILTER OVERVIEW MIDWIVES
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_overview h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.2vh;
}

.mod_overview ul {
  list-style: none;
  padding: 0;
}

.mod_overview ul a {
  color: #41403e;
}

.mod_overview ul a:hover {
  color: #f19d09;
}

.mod_overview ul li.active a {
  color: #f19d09;
}

.mod_overview form {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .mod_overview form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 1vw;
    grid-row-gap: 1vh;
  }
}

.mod_overview input {
  display: none;
}

.mod_overview .checkbox.active,
.mod_overview .radio.active {
  color: #f19d09;
}

.mod_overview .checkbox label,
.mod_overview .radio label {
  width: auto;
}

/*
=========================================================
COMPONENTS CATALOG FILTER SEARCH MIDWIVES
individuelle Anpassungen hebammenlisten.de
=========================================================
*/
@media only screen and (min-width: 600px) {
  .main-content .filter_midwives_search {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "region town" "service language" "termin search" "submit .";
    column-gap: 1vw;
    row-gap: 2vh;
  }
}

@media only screen and (min-width: 1000px) {
  .main-content .filter_midwives_search {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "town search submit";
  }
}

.main-content .filter_midwives_search .ctlg_form_field {
  display: flex;
  flex-flow: column;
}

.main-content .filter_midwives_search .ctlg_form_field select,
.main-content .filter_midwives_search .ctlg_form_field input,
.main-content .filter_midwives_search .ctlg_form_field button {
  width: auto;
  margin: 0;
}

.main-content .filter_midwives_search .ctlg_form_field label {
  font-weight: 700;
  width: 100%;
}

.main-content .filter_midwives_search .region {
  grid-area: region;
}

@media only screen and (min-width: 1000px) {
  .main-content .filter_midwives_search .region {
    display: none;
  }
}

.main-content .filter_midwives_search .town {
  grid-area: town;
}

.main-content .filter_midwives_search .service {
  grid-area: service;
}

@media only screen and (min-width: 1000px) {
  .main-content .filter_midwives_search .service {
    display: none;
  }
}

.main-content .filter_midwives_search .service option[selected] {
  background-color: #f19d09;
  color: white;
}

.main-content .filter_midwives_search .language {
  grid-area: language;
}

@media only screen and (min-width: 1000px) {
  .main-content .filter_midwives_search .language {
    display: none;
  }
}

.main-content .filter_midwives_search .termin {
  grid-area: termin;
}

@media only screen and (min-width: 1000px) {
  .main-content .filter_midwives_search .termin {
    display: none;
  }
}

.main-content .filter_midwives_search .search {
  grid-area: search;
}

.main-content .filter_midwives_search .submit_container {
  align-self: end;
  margin-top: 1.2em;
  grid-area: submit;
}

@media only screen and (min-width: 600px) {
  .main-content .filter_midwives_search .submit_container {
    margin-top: 0;
  }
}

.main-content .filter_midwives_search button {
  display: block;
  width: 100%;
  margin: 0;
}

.main-content .reset {
  font-weight: 700;
}

@media only screen and (min-width: 600px) {
  .main-content .pdf_print {
    display: grid;
    grid-template-columns: minmax(298.717px, 1fr) 1fr;
    grid-template-rows: auto;
    grid-template-areas: "submit .";
    column-gap: 1vw;
    row-gap: 2vh;
  }
}

@media only screen and (min-width: 1000px) {
  .main-content .pdf_print {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". . submit";
  }
}

.main-content .pdf_print button {
  display: block;
  width: 100%;
  margin: 0;
  grid-area: submit;
}

/*
=========================================================
COMPONENTS CATALOG TEASER MIDWIVES
individuelle Anpassungen hebammenlisten.de
=========================================================
*/
.mod_catalogUniversalView.ctlg_midwives {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 1vh;
}

@media only screen and (min-width: 1000px) {
  .mod_catalogUniversalView.ctlg_midwives {
    grid-template-columns: calc(50% - 1vw) calc(50% - 1vw);
    column-gap: 1vw;
  }
}

@media only screen and (min-width: 1000px) {
  .mod_catalogUniversalView .ctlg_view_message {
    grid-column: 1 / span 2;
  }
}

.mod_catalogUniversalView .pagination {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: -1;
}

.teaser_midwife {
  font-size: 0.86667rem;
  border: 1px solid #a5a5a5;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "contact" "more" "available";
}

@media only screen and (min-width: 520px) {
  .teaser_midwife {
    grid-template-columns: 38% 62%;
    grid-template-rows: 1vw auto 1.6em 1vw;
    grid-template-areas: "avatar ." "avatar contact" "available more" "available .";
    height: 205px;
  }
}

@media only screen and (min-width: 550px) {
  .teaser_midwife {
    grid-template-columns: 32% 68%;
  }
}

@media only screen and (min-width: 600px) {
  .teaser_midwife {
    grid-template-columns: 32% 68%;
    max-width: 520px;
  }
}

@media only screen and (min-width: 630px) {
  .teaser_midwife {
    grid-template-columns: 31% 69%;
  }
}

@media only screen and (min-width: 901px) {
  .teaser_midwife {
    grid-template-columns: 36% 64%;
    height: 222px;
  }
}

.teaser_midwife .avatar {
  display: none;
}

@media only screen and (min-width: 520px) {
  .teaser_midwife .avatar {
    display: block;
    background-color: #d9d9d9;
    grid-area: avatar;
  }
}

.teaser_midwife h3 {
  color: #a5a5a5;
  font-size: 1.2em;
  margin: 0 0 1rem;
}

.teaser_midwife h3 a {
  color: #a5a5a5;
}

.teaser_midwife .contact {
  grid-area: contact;
  align-self: center;
  padding: 0.8rem;
}

@media only screen and (min-width: 520px) {
  .teaser_midwife .contact {
    padding: 0 0.8rem;
  }
}

.teaser_midwife .more {
  grid-area: more;
  padding-left: 1rem;
}

.teaser_midwife p {
  font-size: 1;
  margin: 0;
}

.teaser_midwife .available {
  font-style: oblique;
  grid-area: available;
  align-self: end;
  color: white;
  background-color: #f19d09;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.teaser_midwife .available.free {
  background-color: #a2d555;
}

/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.ctlg_hebamme {
  grid-column-start: 1;
  grid-column-end: -1;
}

.ctlg_hebamme .midwife_service {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: wrap;
}

.ctlg_hebamme .midwife_service li {
  border: 1px solid #d9d9d9;
  text-align: center;
  padding: 0.5rem;
  border-radius: 3px;
  margin: 0 0.5rem 0.5rem 0;
}

.ctlg_hebamme .ctlg_entities {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
}

@media only screen and (min-width: 901px) {
  .ctlg_hebamme .ctlg_entities {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.ctlg_hebamme .ctlg_entities .headline,
.ctlg_hebamme .ctlg_entities .ce_text,
.ctlg_hebamme .ctlg_entities .ce_image {
  grid-column-start: 1;
  grid-column-end: -1;
}

.ctlg_hebamme .ctlg_entities .image_container {
  width: auto !important;
}

.ctlg_hebamme .ctlg_entities .image_container img {
  width: auto;
}

.ctlg_hebamme .ctlg_entities .ce_text p {
  margin-top: 0;
}

.ctlg_hebamme .ctlg_entities .availability ul {
  list-style: none;
  padding: 0;
}

.ctlg_hebamme .contact p,
.ctlg_hebamme .language p,
.ctlg_hebamme .region p {
  margin: 0;
}

.ctlg_hebamme .contact .city,
.ctlg_hebamme .language .city,
.ctlg_hebamme .region .city {
  font-weight: 700;
}

.ctlg_hebamme .back {
  clear: both;
  margin-top: 4rem;
}

/*
=========================================================
COMPONENTS CATALOG FORM MIDWIFE
individuelles Formular Frontendediting hebammenlisten.de
=========================================================
*/
.ctlg_form_midwife .widget {
  display: flex;
  flex-flow: wrap;
}

.ctlg_form_midwife .widget label {
  width: 100%;
  margin-right: 0.4rem;
}

@media only screen and (min-width: 600px) {
  .ctlg_form_midwife .widget label {
    width: 13rem;
  }
}

@media only screen and (min-width: 901px) {
  .ctlg_form_midwife .widget label {
    width: 100%;
  }
}

.ctlg_form_midwife .widget input,
.ctlg_form_midwife .widget .chzn-container {
  flex: 1 0 21rem;
  max-width: 100%;
  margin: 0;
}

.ctlg_form_midwife .widget .mce-tinymce {
  width: 100%;
  margin: 0;
}

.ctlg_form_midwife .v-component {
  display: flex;
  flex-flow: wrap;
}

.ctlg_form_midwife .v-component label {
  width: 100%;
  margin-right: 0.4rem;
}

@media only screen and (min-width: 600px) {
  .ctlg_form_midwife .v-component label {
    width: 13rem;
  }
}

@media only screen and (min-width: 901px) {
  .ctlg_form_midwife .v-component label {
    width: 100%;
  }
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component {
  flex: 1 0 21rem;
  max-width: 100%;
  margin: 0;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table {
  width: 100%;
  max-width: 100%;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.date {
  margin-right: 0.4rem;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.date input {
  max-width: 100%;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.operations {
  flex: 1 0 9rem;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.operations button {
  margin: 0 0.4rem 0 0;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.operations button.drag {
  display: none;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .tbody .tr .td.operations button.delete {
  margin: 0;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .thead .tr {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .thead .tr .th.from, .ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .thead .tr .th.to {
  margin-right: 0.4rem;
}

.ctlg_form_midwife .v-component .multi-dates-wizard-component .multi-dates-wizard .table .thead .tr .th.operation {
  flex: 1 0 9rem;
}

.ctlg_form_midwife .widget-fine-uploader {
  display: block;
}

.ctlg_form_midwife .widget-fine-uploader label {
  width: 100%;
}

.ctlg_form_midwife .widget-fine-uploader .image_container {
  float: none;
  max-width: 150px;
  margin-right: 0;
}

.ctlg_form_midwife .qq-uploader .qq-upload-button {
  background: #f19d09;
  border: 1px solid #f19d09;
}

/*
=========================================================
COMPONENTS HEADER BAR
individuelles Frontendmodul hebammenlisten.de
=========================================================
*/
#header_bar {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e8ee;
}

#header_bar .inside {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0.5rem 0;
}

#header_bar ul {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 400px) {
  #header_bar ul {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 400px) {
  #header_bar li {
    margin-left: 1.5rem;
  }
}

#header_bar a {
  font-weight: 700;
}

#header_bar .mod_customnav {
  margin: 0 4%;
}

/*
=========================================================
COMPONENTS FEATURE BOX
individuelle Anpassungen hebammenlisten.de
=========================================================
*/
/*
=========================================================
COMPONENTS CATALOG Univbersal View Frontendediting
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.midwife_entry .new-operator a,
.midwife_entry .act_edit a {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.midwife_entry .new-operator span,
.midwife_entry .act_edit span {
  display: block;
  margin-right: 20px;
}

.midwife_entry .new-operator img,
.midwife_entry .act_edit img {
  width: 50px;
}

.midwife_entry ul.operations {
  padding: 0;
}

.midwife_entry ul.operations li {
  list-style: none;
}

.midwife_entry .ctlg_entity {
  display: flex;
}

.midwife_entry .ctlg_entity span {
  display: block;
  margin-right: 10px;
}

.midwife_entry .ctlg_entity p {
  margin: 0;
}

/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_login .checkbox_container {
  margin-left: 0;
  margin-top: 0;
  padding-left: 0;
  border: none;
}

.mod_login .submit {
  margin-left: 0;
  margin-top: 0;
}

.mod_login form p.error {
  width: 100%;
}

/*
=========================================================
COMPONENTS CATALOG DETAIL MIDWIFE
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mod_registration .checkbox_container {
  border: none;
}

.mod_registration .checkbox_container legend {
  display: none;
}

/*
=========================================================
COMPONENTS TINYMCE_FRONTEND
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.mce-tinymce .mce-btn button {
  width: 100%;
  margin: 0;
}
