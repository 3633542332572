/*
=========================================================
COMPONENTS CATALOG Univbersal View Frontendediting
individuelles Frontenmodul hebammenlisten.de
=========================================================
*/
.midwife_entry {
  .new-operator,
  .act_edit {
	a {
	  font-size:   1.5rem;
	  display:     flex;
	  align-items: center;
	}
	span {
	  display:      block;
	  margin-right: 20px;
	}
	img {
	  width: 50px;
	}
  }
  ul {
	&.operations {
	  padding: 0;
	  li {
		list-style: none;
	  }
	}
  }
  .ctlg_entity {
	display: flex;
	span {
	  display:      block;
	  margin-right: 10px;
	}
	p {
	  margin: 0;
	}
  }
}